exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-advertisements-tsx": () => import("./../../../src/pages/advertisements.tsx" /* webpackChunkName: "component---src-pages-advertisements-tsx" */),
  "component---src-pages-ambulant-care-tsx": () => import("./../../../src/pages/ambulantCare.tsx" /* webpackChunkName: "component---src-pages-ambulant-care-tsx" */),
  "component---src-pages-data-security-tsx": () => import("./../../../src/pages/dataSecurity.tsx" /* webpackChunkName: "component---src-pages-data-security-tsx" */),
  "component---src-pages-daycare-tsx": () => import("./../../../src/pages/daycare.tsx" /* webpackChunkName: "component---src-pages-daycare-tsx" */),
  "component---src-pages-engagement-partner-tsx": () => import("./../../../src/pages/engagementPartner.tsx" /* webpackChunkName: "component---src-pages-engagement-partner-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-intensive-care-tsx": () => import("./../../../src/pages/intensiveCare.tsx" /* webpackChunkName: "component---src-pages-intensive-care-tsx" */),
  "component---src-pages-intensive-nurse-tsx": () => import("./../../../src/pages/intensiveNurse.tsx" /* webpackChunkName: "component---src-pages-intensive-nurse-tsx" */),
  "component---src-pages-jobs-tsx": () => import("./../../../src/pages/jobs.tsx" /* webpackChunkName: "component---src-pages-jobs-tsx" */),
  "component---src-pages-magazines-tsx": () => import("./../../../src/pages/magazines.tsx" /* webpackChunkName: "component---src-pages-magazines-tsx" */),
  "component---src-pages-mission-statement-tsx": () => import("./../../../src/pages/missionStatement.tsx" /* webpackChunkName: "component---src-pages-mission-statement-tsx" */),
  "component---src-pages-nurse-tsx": () => import("./../../../src/pages/nurse.tsx" /* webpackChunkName: "component---src-pages-nurse-tsx" */),
  "component---src-pages-our-nursing-service-tsx": () => import("./../../../src/pages/ourNursingService.tsx" /* webpackChunkName: "component---src-pages-our-nursing-service-tsx" */),
  "component---src-pages-shared-flats-tsx": () => import("./../../../src/pages/sharedFlats.tsx" /* webpackChunkName: "component---src-pages-shared-flats-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */)
}

